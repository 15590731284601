import { flatListToHierarchical } from '@faustwp/core/dist/mjs/utils/flatListToHierarchical';
import { Popover } from '@headlessui/react';
import { __ } from '@wordpress/i18n';
import Link from 'next/link';
import { Logo } from '@/components/elements/Logo/Logo';
import { SkipNavLink } from '@/components/elements/SkipNavLink/SkipNavLink';
import { cn } from '@/utils/helpers/cn';
import {
	MenuItemNodeFragFragment,
	MediaItemFragFragment,
	PrimaryMenuItemsFragFragmentDoc,
} from '@graphqlTypes/graphql';
import { MobileMenu } from './MobileMenu';
import { NavMenu } from './NavMenu';
import type { MenuItemFragmentWithChildren } from '@/types';

export const Header = ( {
	menu,
	logo,
	className,
}: {
	menu?: MenuItemNodeFragFragment[];
	logo?: MediaItemFragFragment | null;
	className?: string;
} ) => {
	const menuItems = menu?.length
		? ( flatListToHierarchical( menu ) as MenuItemNodeFragFragment[] )
		: [];

	const headerClasses = cn( `relative mx-auto has-global-padding`, className );

	return (
		<Popover className={ headerClasses } as="header">
			<SkipNavLink />
			<nav className="max-w-screen wp-block-navigation flex items-center justify-between">
				<div className="flex w-full items-center px-4 py-6 xl:px-8">
					<Link href="/">
						<span className="sr-only">{ __( 'AxePress Development', 'axewp' ) }</span>
						<Logo logo={ logo } height={ 60 } width={ 384 } />
					</Link>
				</div>
				<NavMenu menuItems={ menuItems as MenuItemFragmentWithChildren[] } />
				<MobileMenu menuItems={ menuItems } logo={ logo } />
			</nav>
		</Popover>
	);
};

Header.fragments = {
	entry: PrimaryMenuItemsFragFragmentDoc,
	key: 'PrimaryMenuItemsFrag',
};
