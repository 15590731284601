import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Transition } from '@headlessui/react';
import { __ } from '@wordpress/i18n';
import NextLink from 'next/link';
import { Fragment } from 'react';
import { Link } from '@/components/elements/Link/Link';
import { Logo } from '@/components/elements/Logo/Logo';
import { GithubMenu } from './GithubMenu';
import type { MenuItemNodeFragFragment, MediaItemFragFragment } from '@graphqlTypes/graphql';

export const MobileMenu = ( {
	menuItems,
	logo,
}: {
	menuItems: MenuItemNodeFragFragment[];
	logo: MediaItemFragFragment | null | undefined;
} ) => {
	return (
		<>
			<div className="mt-2 md:hidden">
				<Popover.Button className="font-heading inline-flex items-center justify-center rounded-md p-4 focus:outline-none focus:ring-2 focus:ring-inset">
					<span className="sr-only">Open main menu</span>
					<FontAwesomeIcon icon={ faBars } />
				</Popover.Button>
			</div>
			<Transition
				as={ Fragment }
				enter="duration-200 ease-out"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="duration-100 ease-in"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				<Popover.Panel
					focus
					className="absolute inset-x-0 top-0 z-20 origin-top-right transform p-2 transition md:hidden"
				>
					<div className="has-steel-100-background-color divide-y-2 divide-steel-400 rounded-lg shadow-lg ring-1 ring-steel-200 ring-opacity-5">
						<div className="flex items-center justify-between px-5 pb-2 pt-5">
							<NextLink href="/">
								<span className="sr-only">{ __( 'AxePress Development', 'axewp' ) }</span>
								<Logo logo={ logo } height={ 48 } />
							</NextLink>
							<div className="mr-2">
								<Popover.Button className="font-heading inline-flex items-center justify-center rounded-md p-4 focus:outline-none focus:ring-2 focus:ring-inset">
									<span className="sr-only">Close menu</span>
									<FontAwesomeIcon icon={ faTimes } />
								</Popover.Button>
							</div>
						</div>
						<div className="mt-6 gap-8 px-5 pb-4">
							{ menuItems.map( ( menuItem ) => (
								<Link
									key={ menuItem.id }
									href={ menuItem.path ?? '' }
									className="hover:bg-grey-50 flex items-center rounded-lg p-2"
								>
									<span className="textgrey-900 ml-3 text-base font-medium">
										{ menuItem.label }
									</span>
								</Link>
							) ) }
							<div className="mt-6 flex flex-row items-center gap-2">
								<GithubMenu />
							</div>
						</div>
					</div>
				</Popover.Panel>
			</Transition>
		</>
	);
};
