import { __ } from '@wordpress/i18n';
import { Image as FeaturedImage } from '@/components/elements/Image/Image';
import { SiteIdentityFragFragmentDoc, type MediaItemFragFragment } from '@graphqlTypes/graphql';

export const Logo = ( {
	className,
	logo,
	height,
	width,
}: {
	className?: string;
	logo: MediaItemFragFragment | null | undefined;
	width?: number;
	height?: number;
} ) => {
	// If logo is empty return early.
	if ( ! logo ) {
		return null;
	}

	return (
		<FeaturedImage
			className={ className }
			image={ logo }
			alt={ __( 'AxePress Development Logo', 'axewp' ) }
			width={ width }
			height={ height }
		/>
	);
};

Logo.fragments = {
	entry: SiteIdentityFragFragmentDoc,
	key: 'SiteIdentityFrag',
};
