import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faHeart } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { __ } from '@wordpress/i18n';
import { cn } from '@/utils/helpers/cn';
import style from './GithubMenu.module.scss';

export const GithubMenu = () => {
	return (
		<>
			<li className="wp-block-navigation-item wp-block-navigation-link ">
				<a
					href="https://github.com/axewp"
					target="_blank"
					rel="noopener noreferrer"
					className={ style.menuItem }
				>
					<FontAwesomeIcon icon={ faGithub } size="2x" />
					<span className="sr-only">{ __( 'AxePress Development on Github', 'axewp' ) }</span>
				</a>
			</li>

			<li className="wp-block-navigation-item wp-block-navigation-link">
				<div className="is-style-outline wp-block-button">
					<a
						href="https://github.com/sponsors/AxeWP"
						target="_blank"
						rel="noopener noreferrer"
						className={ cn(
							'has-text-color wp-element-button wp-block-button__link ',
							style.menuItem,
							style.sponsorButton
						) }
					>
						<FontAwesomeIcon icon={ faHeart } className="text-primary" />
						&nbsp;
						<span className="wp-block-button__link-text ">{ __( 'Sponsor', 'axewp' ) }</span>
					</a>
				</div>
			</li>
		</>
	);
};
