import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Link } from '@/components/elements/Link/Link';
import { cn } from '@/utils/helpers/cn';
import { GithubMenu } from './GithubMenu';
import type { MenuItemFragmentWithChildren } from '@/types';

export const NavMenu = ( { menuItems }: { menuItems: MenuItemFragmentWithChildren[] } ) => {
	return (
		<Popover.Group
			as="ul"
			className="font-heading mt-2 hidden shrink-0 items-center space-x-8 px-4 font-semibold md:flex"
		>
			{ menuItems &&
				menuItems.map( ( item ) => {
					// If the item has no children, render a regular menu item.
					if ( ! item.children || ! item.children.length ) {
						return (
							<li key={ item.id } className="wp-block-navigation-item wp-block-navigation-link">
								<Link href={ item.path ?? '' }>{ item.label }</Link>
							</li>
						);
					}

					return (
						<Popover key={ item.id } className="relative">
							{ ( { open } ) => (
								<>
									<Popover.Button
										className={ cn(
											open ? 'textgrey-900 dark:text-white' : 'textgrey-700 dark:textgrey-100',
											'wp-block-navigation-item wp-block-navigation-link group inline-flex items-center rounded-md p-2 uppercase focus:outline-none focus:ring-1'
										) }
									>
										<span className="wp-block-navigation-item__label">{ item.label }</span>
										<FontAwesomeIcon
											icon={ faChevronDown }
											className="textgrey-400 ml-2 h-5 w-5 group-hover:text-red"
											aria-hidden="true"
										/>
									</Popover.Button>
									<Transition
										as={ Fragment }
										enter="transition ease-out duration-200"
										enterFrom="opacity-0 translate-y-1"
										enterTo="opacity-100 translate-y-0"
										leave="transition ease-in duration-150"
										leaveFrom="opacity-100 translate-y-0"
										leaveTo="opacity-0 translate-y-1"
									>
										<Popover.Panel className="absolute left-1/2 z-20 mt-3 max-w-md -translate-x-1/2 transform px-4 sm:px-0 lg:left-8 lg:right-auto lg:ml-8 lg:max-w-3xl">
											<div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-steel-200 ring-opacity-5">
												<div className="has-steel-100-background-color relative grid gap-6 px-5 py-6 sm:gap-4 sm:p-8">
													{ item.children.map( ( menuItem ) => {
														return (
															<Link
																key={ menuItem.id }
																href={ menuItem.path ?? '' }
																className="hover:bg-grey-50 -m-3 flex items-start rounded-lg p-3"
															>
																<div>
																	<p className="textgrey-900 text-base font-medium dark:text-white">
																		{ menuItem.label }
																	</p>
																	<p className="textgrey-500 dark:textgrey-100 has-small-font-size mt-1">
																		{ menuItem.description }
																	</p>
																</div>
															</Link>
														);
													} ) }
												</div>
											</div>
										</Popover.Panel>
									</Transition>
								</>
							) }
						</Popover>
					);
				} ) }
			<GithubMenu />
		</Popover.Group>
	);
};
