import { MAIN_CONTENT_ID } from '@/constants/selectors';
import type { HTMLAttributes, PropsWithChildren } from 'react';

export const Main = ( {
	className,
	style,
	tabIndex,
	children,
	...rest
}: PropsWithChildren & HTMLAttributes< HTMLElement > ) => {
	return (
		<main
			{ ...rest }
			id={ MAIN_CONTENT_ID }
			className={ className }
			tabIndex={ tabIndex ?? -1 }
			style={ style }
		>
			{ children }
		</main>
	);
};
