import { Source_Sans_3 as SourceSans3 } from 'next/font/google';
import { PropsWithChildren } from 'react';
import { Loading } from '@/components/elements/Loading/Loading';
import { SEO } from '@/features/SEO/SEO';
import { NodeWithSeoFragFragmentDoc, SeoFragFragment } from '@graphqlTypes/graphql';

// Fonts
const sourceSans = SourceSans3( {
	weight: [ '400', '700' ],
	style: [ 'normal', 'italic' ],
	subsets: [ 'latin' ],
	variable: '--wp--preset--font-family--source-sans-pro',
	// @see https://stackoverflow.com/a/76484168
	display: 'swap',
	adjustFontFallback: false,
} );

export const Layout = ( {
	loading,
	seo,
	children,
}: PropsWithChildren< {
	loading: boolean;
	seo: SeoFragFragment;
} > ) => {
	if ( loading ) {
		return <Loading />;
	}

	return (
		<>
			{ seo && <SEO { ...seo } /> }
			<div className={ `wp-site-blocks ${ sourceSans?.variable } dark font-sans` }>
				{ children }
			</div>
		</>
	);
};

Layout.fragments = {
	entry: NodeWithSeoFragFragmentDoc,
	key: 'NodeWithSeoFrag',
};
